export const enum Approaches {
    ReadRetrieveRead = "rrr",
    ReadNative = "read",
}

export type ChatRequestOverrides = {
    semanticRanker?: boolean;
    filterCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    suggestFollowupQuestions?: boolean;
    gptVersion?: string;
    nativeSearch?: boolean;
    llmModelName?: string;
    agentType?:string;
    profile?:string;
    tools?: Array<Tools>;
    showPreviewFeatures?: boolean;
    use_files?: boolean;
};

export type ChatResponse = {
    answer: any;
    thoughts: string | null;
    data_points?: SupportingContentData[];
    error?: string;
    error_code?: string;
    responseID?: string;
    feedbackType?: string;
    comment?: string;
    sharePrompt?: string;
    feedbackSubmissionTime?: string;
    conversationID?: string;
    issue?: string;
    settingProfile?: string;
    setting?: string;
    query?: string;
    overrides?: ChatRequestOverrides;
    userName?: string;
    appVersion: string;
    upload_session_id?: string;
    message_id?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string | {
        type: string;
        content: string;
    };
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: ChatRequestOverrides;
    version: string;
    conversation_id: string;
    files?: any[]
    uploadSessionId?: string;
    conversationTitle?: string;
    category?: {};
};

export type SupportingContentData = {
    title: string,
    content: string,
    source: string
}

export type AzureADTokenUserClaims = {
    typ: string,
    val: string
}

export type AzureADToken = {
    user_id: string,
    access_token?: string,
    id_token?:string,
    expires_on: string,
    provider_name: string,
    user_claims?: AzureADTokenUserClaims[]
}

export interface Profile {
    temperature: number,
    llmModelName: string,
    gptVersion: string,
    version: string,
    approach?: Approaches,
    nativeSearch : boolean,
    useSemanticRanker: boolean,
    agentType?: string,
    tools?: Array<Tools>,
    cardIdentifier: string,
}

export interface Tools {
    name: string,
    filters?: Array<string>,
}

export interface Cards {
    title: string,
    description: string,
    backDescription: string
}

export type FeedbackDataType = {
    feedbackType: string;
    comment: string;
    sharePrompt: string;
    feedbackSubmissionTime: string;
    responseID: string;
    issue: string;
};

export type Conversation = {
    title?: string;
    conversationTitle?: string;
    messages: [user: string, response: ChatResponse][];
    conversationID: string;
    uploadedFiles?: string[];
    uploadSessionId?: string;
};

export type GenieTableColumns = {
    name: string;
    position: number;
    type_name: string;
    type_text: string;
    type_precision?: number;
    type_scale?: number;
};

export type GenieTableSchema = {
    column_count: number;
    columns: GenieTableColumns[];
};

export type DataTypedArray = {
    values: { str: string }[];
};

export type StatementResponse = {
    manifest: {
        schema: GenieTableSchema;
        chunks: any[];
        format: string;
        total_byte_count: number;
        total_chunk_count: number;
        total_row_count: number;
        truncated: boolean;
    };
    result: {
        chunk_index: number;
        data_typed_array: DataTypedArray[];
        row_count: number;
        row_offset: number;
    };
    statement_id: string;
    status: {
        state: string;
    };
};
export interface UserConversation {
  conversationID: string;
  conversationTitle: string;
  profile: string;
  category: {};
  user_id?: string;
  date_created?: string;
  date_last_accessed?: string;
}

export interface ConversationHistory {
    user: string;
    bot: string;
    message_id: string;
    top?: number;
    filterCategory?: string;
    temperature?: number;
    promptTemplate?: string;
    gptVersion?: string;
    nativeSearch?: boolean;
    tools?: any[];
    showPreviewFeatures?: boolean;
    use_files?: boolean;
    appVersion?: string;
}
export interface ConversationDetail {
    conversationID: string;
    conversationHistory: ConversationHistory[];
    files: string[];
    user_id?: string;
  }

export interface ConversationThoughtProcess {
  conversationID: string;
  user_id?: string;
  conversationData: {
    message_id: string;
    thoughtprocess: string;
    datapoints: SupportingContentData[];
  }[];
}

export type TransformedData = {
  userConversations?: UserConversation[];
  conversationDetails?: ConversationDetail[];
  conversationThoughtProcess?: ConversationThoughtProcess[];
};